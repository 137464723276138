<template>
  <div class="contact">
    <Head :headdata="headdata"></Head>
    <div class="contactMain">
      联系
    </div>
  </div>
</template>

<script>
import Head from "@/components/header.vue";
export default {
  data() {
    return {
      headdata: this.$t("m.onHeaddata"),
    };
  },
  components: {
    Head,
  },
  created() {
    this.getData();
  },
 
  methods: {
    getData() {
      this.axios
        .get(this.GLOBAL.serverSrc + "/wc/contract") // 真实环境中，后端会根据参数group返回新的图片数组，这里我用一个惊呆json文件模拟
        .then((res) => {
          console.log(res);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.contact {
  height: 100%;
  width: 100%;
  .contactMain {
    min-height: 1100px;
    padding: 78px 200px 0 200px;
  }
}
</style>